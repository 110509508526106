<template>
  <div class="farmMachinery">

    <!-- 农户补贴申请 -->
    <div class="subsidyBox">
      <el-form :inline="true" :model="form" label-position="left" :rules="formRules" ref="ruleForm">
        <div class="LoanApplication">
          <!-- 基础信息 -->
          <div class="BasicInformation">
            <div class="title">
              <p>基础信息</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="申请人：">
                  <el-input v-model="form.applyUser" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="身份证/机构代码：">
                  <el-input v-model="form.idCard" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="银行账户：">
                  <el-input v-model="form.bankCard" :disabled="true" placeholder=""></el-input>
                </el-form-item>

                <div class="LegalPersonIDCard">
                  <label class="el-form-item__label" style="width: 110px">
                    法定代表人身份证照片：
                  </label>
                  <el-image   style="width: 150px; height: 80px;margin-left: 5px;" :src="
                    baseUrl + 'admin/file/get?ossFilePath=' + userCardFrontImg
                  ">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/icon/imgError.png" />
                    </div>
                  </el-image>
                  <el-image style="width: 150px; height: 80px;;margin-left: 5px;" :src="
                    baseUrl + 'admin/file/get?ossFilePath=' + userCardBackImg
                  ">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>

              <div class="Right">
                <el-form-item label="联系电话：">
                  <el-input v-model="form.phone" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="地址：">
                  <el-input v-model="form.address" :disabled="true" placeholder=""></el-input>
                  <!-- <el-cascader :disabled="true" style="width: 190px" ref="address" v-model="form.areaCode" :props="prop"
                    :options="proviceArr" :show-all-levels="false" @change="regionChange"></el-cascader> -->
                </el-form-item>
                <el-form-item label="开户行：">
                  <el-input v-model="form.bankName" :disabled="true" placeholder=""></el-input>
                </el-form-item>

                <div class="BusinessLicense" v-if="form.businessLicense">
                  <label class="el-form-item__label" style="width: 105px">
                    营业执照：
                  </label>
                  <el-image style="width: 150px; height: 80px" :src="
                    baseUrl + 'admin/file/get?ossFilePath=' + businessLicense
                  ">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>
              <div class="backBtn">
                <el-button type="primary" @click="back">返回</el-button>
              </div>
            </div>
          </div>
          <!-- 填写自身基础信息 -->
          <div class="SelfBasicInformation">
            <div class="title">
              <p>申请信息</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="农机类型：">
                  <el-cascader :disabled="true" style="width: 190px" ref="cascader"
                    v-model="form.agriculturalMachineryType" :props="props" :options="machineryList"
                    :show-all-levels="false" @change="regionChange"></el-cascader>
                </el-form-item>
                <el-form-item label="农机价格：">
                  <el-input :disabled="true" v-model="form.agriculturalMachineryPrice" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="农机型号：">
                  <el-input :disabled="true" v-model="form.agriculturalMachineryModel" placeholder=""></el-input>
                </el-form-item>
              </div>
              <div class="Right">
                <el-form-item label="农机生产商：">
                  <el-input :disabled="true" v-model="form.agriculturalMachineryManufacturer" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="农机经销商：">
                  <el-input :disabled="true" v-model="form.agriculturalMachineryDealer" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="发动机编号：">
                  <el-input :disabled="true" v-model="form.engineNumber" placeholder=""></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <!-- 农机购置发票 -->
          <div class="OtherAccessories">
            <div class="title">
              <div class="tit">
                <p>农机购置发票</p>
              </div>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :disabled="true" :file-list="fileList" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in agriculturalMachineryInvoiceUrl" :key="index">

                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <div>
                  {{ item.FilesName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 其他证明材料 -->
          <div class="OtherAccessories">
            <div class="title">
              <div class="tit">
                <p>其他证明材料</p>
              </div>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :disabled="true" :limit="6" :action="uploadUrl"
                name="files" :auto-upload="false" :file-list="fileList" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in otherFiles" :key="index">

                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <div>
                  {{ item.FilesName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 鉴定证书 -->
          <div class="OtherAccessories">
            <div class="title">
              <div class="tit">
                <span>所购机具试验鉴定证书</span>
                <span>(可要求农机出售方提供)</span>
              </div>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :disabled="true" :auto-upload="false" :file-list="fileList" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in agriculturalMachineryCertificateUrl" :key="index">

                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <div>
                  {{ item.FilesName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>


  </div>
</template>

<script>
  import { tree } from "@/api/subsidy";
  import { getAreaTree } from "@/api/certificate";
  import { getMySubsidyById } from '@/api/subsidy';
  import { removeEmptyChildren } from "@/js/utils/util";
  export default {
    data() {
      return {
        form: {
          applyUser: "", //申请人
          phone: "", //联系电话
          idCard: "", //身份证号
          areaCode: "", //地址
          bankCard: "", //银行账户
          bankName: "", //开户行
          address: "", //地址名
          agriculturalMachineryType: '',//农机类型
          agriculturalMachineryManufacturer: "", //农机生产商
          agriculturalMachineryPrice: "", //农机价格
          agriculturalMachineryDealer: "", //农机经销商
          agriculturalMachineryModel: "", //农机型号
          engineNumber: "", //发动机编号

        }, //这是输入基础信息
        types: [
          {
            label: "集体经济组织",
            value: "3",
          },
          {
            label: "农业企业",
            value: "41",
          },
          {
            label: "家庭农场",
            value: "42",
          },
          {
            label: "合作社",
            value: "43",
          },
        ],
        props: {
          label: "name",
          children: "children",
          value: "id",
          checkStrictly: true,
          emitPath: false,
        }, //农机类型选择器
        prop: {
          label: "areaName",
          children: "children",
          value: "id",
          checkStrictly: true,
        }, //省市区选择器
        formRules: {

        },
        fileList: [], //上传图片的列表,
        proviceArr: [], //省市区列表
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        baseUrl: window.globalUrl.BASE_API,
        machineryList: [], //农机类型列表
        dialogVisible: true,//控制弹窗显隐
        businessLicense: "", // 存储营业执照
        userCardFrontImg: "", // 存储人面像图片
        userCardBackImg: "", // 存储国徽像图片
        agriculturalMachineryInvoiceUrl: [], //农机购置发票
        otherFiles: [], //其他证明材料
        agriculturalMachineryCertificateUrl: [], //鉴定证书
      }
    },
    created() {
      this.getMySubsidyById()
    },
    mounted() {

      this.getRegions()
      this.getTree()
    },
    methods: {
      // 回显数据
      initFrom(obj) {
        this.form.address = obj.machineryPurchaseDto.address
        this.form.applyUser = obj.machineryPurchaseDto.applyUser
        this.form.phone = obj.machineryPurchaseDto.phone
        this.form.idCard = obj.machineryPurchaseDto.idCard
        this.form.areaCode = obj.machineryPurchaseDto.areaCode
        this.form.bankCard = obj.machineryPurchaseDto.bankCard
        this.form.bankName = obj.machineryPurchaseDto.bankName
        this.form.address = obj.machineryPurchaseDto.address
        this.form.agriculturalMachineryManufacturer = obj.machineryPurchaseDto.agriculturalMachineryManufacturer
        this.form.agriculturalMachineryPrice = obj.machineryPurchaseDto.agriculturalMachineryPrice
        this.form.agriculturalMachineryDealer = obj.machineryPurchaseDto.agriculturalMachineryDealer
        this.form.agriculturalMachineryModel = obj.machineryPurchaseDto.agriculturalMachineryModel
        this.form.engineNumber = obj.machineryPurchaseDto.engineNumber
        this.form.agriculturalMachineryType = obj.machineryPurchaseDto.agriculturalMachineryType
        this.agriculturalMachineryInvoiceUrl = JSON.parse(obj.machineryPurchaseDto.agriculturalMachineryInvoiceUrl)
        this.otherFiles = JSON.parse(obj.machineryPurchaseDto.otherFiles)
        this.agriculturalMachineryCertificateUrl = JSON.parse(obj.machineryPurchaseDto.agriculturalMachineryCertificateUrl)
        this.userCardFrontImg = obj.machineryPurchaseDto.legalIdcardPicFront ? obj.machineryPurchaseDto.legalIdcardPicFront : ''
        this.userCardBackImg = obj.machineryPurchaseDto.legalIdcardPicBack ? obj.machineryPurchaseDto.legalIdcardPicBack : ''
        this.businessLicense = obj.machineryPurchaseDto.businessLicense ? obj.machineryPurchaseDto.businessLicense : ''
      },
      //附件下载功能
      async AddFiles(item) {
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = "/api/admin/file/get?ossFilePath=" + item.imgUrl;
        dom.setAttribute("download", item.FilesName);
        document.body.appendChild(dom);
        dom.click();
      },
      // 返回
      back() {
        this.$router.go(-1); // 返回上一级路由
      },
      //递归去掉数据最后一级children
      checkChild(data) {
        return data
          .map((item) => {
            if (item.hasOwnProperty("children") && Array.isArray(item.children)) {
              // 递归处理children
              item.children = this.checkChild(item.children);
              // 如果children为空数组，则删除children属性
              if (item.children.length === 0) {
                delete item.children;
              }
              return item;
            } else {
              // 如果没有children节点，返回一个空数组
              return item;
            }
          })
          .filter((item) => item); // 过滤掉空值，确保不返回 false
      },
      //农机类型
      async getTree() {
        let res = await tree();
        this.checkChild(res.data);
        let newData = this.checkChild(res.data);
        this.machineryList = newData;
        console.log("农机类型", this.machineryList)
      },
      // 获取行政区树
      async getRegions() {
        let param = {
          level: "5",
          // parentCode:this.topSelectAreacode
        };
        let res = await getAreaTree(param);
        // console.log("行政区",res);
        let { code, data, msg } = res;
        if (code == 0) {
          this.proviceArr = removeEmptyChildren(data);
        } else {
          this.$message.error(msg);
        }
        console.log("省市区", this.proviceArr)
      },
      //  详情页面数据
      async getMySubsidyById() {
        let res = await getMySubsidyById({ id: this.$route.query.id })
        if (res.code == 0) {

          this.initFrom(res.data)
        }
      },

      //行政区做切换
      regionChange() {
        let node = this.$refs.address.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        console.log(node.hasChildren);
        console.log(this.areaHasChild, "切换行政区");
        console.log(this.identityObj.areaCode);
        this.form.areaCode = node.value;
        this.form.address = node.pathLabels.join("");
        setTimeout(() => {
          this.$refs.form.validateField("address");
        }, 100);
      },
      // 农机类型切换
      regionChange2() {
        let node = this.$refs.cascader.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        console.log(node.hasChildren);
        console.log(this.areaHasChild, "切换行政区");
        console.log(this.identityObj.areaCode);
        this.form.agriculturalMachineryType = node.value;

        setTimeout(() => {
          this.$refs.form.validateField("cascader");
        }, 100);
      },
      //  返回按钮
      back() {
        this.$router.go(-1); // 返回上一级路由
      },

    }
  }
</script>

<style lang="less" scoped>
  .farmMachinery {
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;

    .subsidyBox {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;

      margin-top: 50px;

      .LoanApplication {
        width: 100%;
        height: 100%;

        /*基础信息*/
        .BasicInformation {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;

            .Left {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 130px;
                  height: 40px;
                }
              }



            }

            .Right {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 124px;
                  height: 40px;
                }
              }
            }
          }
        }

        /*填写自身相关信息*/
        .SelfBasicInformation {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;

            .Left {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 130px;
                  height: 40px;
                }
              }



            }

            .Right {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 124px;
                  height: 40px;
                }
              }
            }
          }
        }
      /* 身份证 */
        .LegalPersonIDCard {
          display: flex;

          /deep/.el-form-item__label {
            padding-right: 0px;
          }

          /deep/.el-image {
            img {
              width: 150px;
              height: 80px;

            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }

        /* 营业执照*/
        .BusinessLicense {
          display: flex;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding-left: 20px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }


        /*其他附件*/
        .OtherAccessories {
          border: solid 1px #ccc;
          display: flex;

          .title {
            width: 12%;
            display: flex;
            justify-content: center;
            align-items: center;

            .tit {
              p {
                display: block;
                font-size: 15px;
                font-weight: bold;

                i {
                  color: red;
                }

              }

              span {
                display: block;
                font-size: 15px;
                font-weight: bold;

                i {
                  color: red;
                }
              }
            }

          }

          .textContent {
            width: 100%;
            height: 120px;
            display: flex;
            border: solid 1px #ccc;

            .Item {
              width: 150px;
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: center;
              position: relative;
              padding: 10px;

              .el-image {
                width: 150px;
                height: 85px;
                background: #e6e3e3;

                img {
                  width: 200px;
                  height: 50px;
                }

                i {
                  font-size: 50px;
                  font-weight: bold;
                  margin-top: 20px;
                }
              }

              span {
                margin-top: 5px;
              }

              .del {
                width: 20px;
                height: 20px;
                border: solid 1px black;
                border-radius: 50px;
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 5000;
                text-align: center;
                line-height: 20px;
              }
            }

            .Item:hover {
              .del {
                display: block;
              }
            }

            .upload-demo {
              i {
                font-size: 50px;
                font-weight: bold;
                margin-top: 30px;
              }
            }
          }
        }

        /* 提交申报*/
        .footer {
          margin: 20px 0px;
          display: flex;
          justify-content: center;

        }
      }
    }

  }
</style>